import React, { FC, useCallback, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useAllConsultants } from 'common/hooks/useAllConsultants';
import AutocompleteField from 'form/AutocompleteField';
import SelectFiled from 'form/SelectField';
import TextFiled from 'form/TextField';
import _isEmpty from 'lodash/isEmpty';
import { selectMockStatus } from 'src/common/constants';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  emailValidator,
  lettersOnlyValidator,
  requiredStringValidator,
} from 'valtech-core/common/form/validators';
import {
  ADD_FTL,
  ADD_USER_FTL,
  CANCEL_FTL,
  EDIT_USER_FTL,
  EMAIL_FTL,
  NAME_FTL,
  ROLE_FTL,
  SAVE_FTL,
  STATUS_FTL,
} from 'valtech-core/common/ftl';
import { FieldControl } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useHandleSettingsUser } from './SettingsUserForm.hooks';

import { EFormFieldName } from '../form.types';
import { defaultInitialValues, selectMockRoles } from './SettingsUserForm.constants';

export interface ISettingsUserFormProps {
  userId: number;
  name: string;
  email: string;
  role: string;
  status: string;
  consultant: { id: number; label: string; email: string };
}
export interface IEditSettingsUserFormProps<T> {
  onClose: VoidFunction;
  initialValues?: Partial<T>;
  defaultValues?: Partial<T>;
  onSubmit?: (data: T) => void;
  settingsUserId?: number;
  hasDelete?: boolean;
}

const SettingsUserForm: FC<IEditSettingsUserFormProps<ISettingsUserFormProps>> = ({
  onClose,
  initialValues = defaultInitialValues,
}) => {
  const { l10n } = useLocalization();
  const { control, setValue, watch, handleSubmit, formState } = useForm<ISettingsUserFormProps>({
    defaultValues: initialValues,
  });
  const { consultants: consultantsList } = useAllConsultants();
  const { createSettingsUser, updateSettingsUser, loading } = useHandleSettingsUser();

  const formChanged = formState.dirtyFields;

  const formSubmit: SubmitHandler<ISettingsUserFormProps> = useCallback(
    data => {
      if (_isEmpty(formChanged)) return;
      // If user doesn't exist data.userId will be 0
      if (data.userId) {
        const dataUpdated = { userId: data.userId };

        for (const key in data) {
          if (data[key] !== initialValues[key]) {
            dataUpdated[key] = data[key];
          }
        }
        updateSettingsUser(data, dataUpdated).then(success => success && onClose());
      } else {
        createSettingsUser(data).then(success => success && onClose());
      }
    },
    [onClose, formChanged],
  );

  const onCancel = () => {
    onClose();
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const hasConsultantChanged = name === EFormFieldName.Consultant && type === 'change';
      const selectedConsultant = value.consultant;
      if (hasConsultantChanged && selectedConsultant) {
        setValue(EFormFieldName.Email, selectedConsultant?.email || '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, consultantsList]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>
        {initialValues.userId ? l10n.getString(EDIT_USER_FTL) : l10n.getString(ADD_USER_FTL)}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AutocompleteField
            label={l10n.getString(NAME_FTL)}
            name={EFormFieldName.Consultant}
            control={control as unknown as FieldControl}
            data={consultantsList}
            validate={value => lettersOnlyValidator(value?.label)}
            disabled={!!initialValues.userId}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFiled
            label={l10n.getString(EMAIL_FTL)}
            name={EFormFieldName.Email}
            control={control as unknown as FieldControl}
            validate={emailValidator}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(ROLE_FTL)}
            name={EFormFieldName.Role}
            data={selectMockRoles}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(STATUS_FTL)}
            name={EFormFieldName.Status}
            data={selectMockStatus}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
          />
        </Grid>
      </Grid>

      <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
        <Button
          onClick={handleSubmit(formSubmit)}
          // TODO: Dirty fields don't work with Autocomplete in these case. Further invastigation is needed.
          // disabled={!formState.isDirty || loading}
          variant='contained'>
          {initialValues.userId ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </form>
  );
};

export default SettingsUserForm;
